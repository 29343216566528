import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// Core Components

function Feature6() {
  return (
    <>
      <div className="section features-6">
        <Container>
          <Row className="align-items-center">
            <Col lg="6">
              <div className="info info-horizontal info-hover-primary">
                <div className="icon icon-shape icon-shape-info rounded-circle text-white">
                  <i className="ni ni-html5 text-info"></i>
                </div>
                <div className="description pl-4">
                  <h5 className="title">For Sales Teams</h5>
                  <p>
                    We have a track record spanning decades of implementing proven sales strategies that provide tangible value to any in-person sales team.
                  </p>
                  {/* <a
                    className="text-info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Learn more
                  </a> */}
                </div>
              </div>
              <div className="info info-horizontal info-hover-primary">
                <div className="icon icon-shape icon-shape-info rounded-circle text-white">
                  <i className="ni ni-app text-info"></i>
                </div>
                <div className="description pl-4">
                  <h5 className="title">For Forward Thinking Founders</h5>
                  <p>
                    We've developed best-in-class software systems that enable small businesses to fully automate time-consuming processes; all while keeping you updated with key metrics. 
                  </p>
                  {/* <a
                    className="text-info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Learn more
                  </a> */}
                </div>
              </div>
              <div className="info info-horizontal info-hover-primary">
                <div className="icon icon-shape icon-shape-info rounded-circle text-white">
                  <i className="ni ni-bell-55 text-info"></i>
                </div>
                <div className="description pl-4">
                  <h5 className="title">For AI Hopefuls</h5>
                  <p>
                    Our team has been knee-deep in AI for a decade, and has the entrepreneurial know-how to implement the latest AI tools into your small businesses workflow. 
                  </p>
                  {/* <a
                    className="text-info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Learn more
                  </a> */}
                </div>
              </div>
            </Col>
            <Col className="mx-md-auto" lg="6" xs="10">
              <img
                alt="..."
                className="ml-lg-5"
                src={require("assets/img/ill/ill.png")}
                width="100%"
              ></img>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Feature6;
